const getSiteUrl = (organisation, destination = "") => {
  let url;

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
    if (!!organisation && !!organisation?.slug) {
      url = `https://${organisation.slug}.ycn.org`;
    } else {
      url = "https://www.ycn.org";
    }
  } else {
    if (!!organisation && !!organisation?.slug) {
      url = `https://${organisation.slug}.client.ycn.ddev.site`;
    } else {
      url = "https://client.ycn.ddev.site";
    }
  }

  if (!!destination) url = `${url}${destination}`;

  return url;
};

export default getSiteUrl;
