import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Menu from "@/components/Menu";
import getSiteUrl from "@/lib/getSiteUrl";
import state from "@/state";
import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import { signOut, useSession } from "next-auth/react";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useSnapshot } from "valtio";

const Layout = ({
  children,
  navigation = {},
  withHero = true,
  site = "default",
  siteLogo = null,
  hideYcnBranding = false,
  hideHeaderBorder = false,
}: {
  children: React.ReactNode;
  navigation: any;
  withHero?: boolean;
  site?: string;
  siteLogo?: {
    dark: any;
    light: any;
  } | null;
  hideYcnBranding?: boolean;
  hideHeaderBorder?: boolean;
}) => {
  const stateSnapshot = useSnapshot(state);

  const router = useRouter();

  /*
		Handle user login status
	*/
  const { data: session, status } = useSession();

  // We need to check if the session.isValid. It might be invalid because a token wasn't refreshed
  // If it isn't valid, we need to properly sign out the user
  useEffect(() => {
    if (!session) return;

    if (!session.isValid) {
      signOut();
    }
  }, [session]);

  useEffect(() => {
    if (!session) return;

    // If a user is logged in, push the userId to the datalayer
    if (session?.user?.id) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: session.user.id, // Pushing userId to the dataLayer
      });
    }

    // If a user is logged in, identify the user in HubSpot
    if (typeof window !== "undefined" && window._hsq) {
      window._hsq.push([
        "identify",
        {
          email: session.user?.email,
          firstname: session.user?.firstName,
          lastname: session.user?.lastName,
          company: session.user?.organisation?.title,
        },
      ]);
    }
  }, [session]);

  useEffect(() => {
    if (!session) return;

    // See whether this user is associated with an origanisation
    const organisation = session.user?.organisation;

    if (!organisation) return;

    // If the user has a site, and it isn't equivalent to the current one, redirect, unless it's a page route
    if (
      organisation.hasSite &&
      router.route !== "/[...slug]" &&
      site !== organisation.slug &&
      process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
    ) {
      router.push(getSiteUrl(organisation, router.asPath));
      return;
    }

    // If a user is logged in, check if they're on the right version of the site
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, site]);

  return (
    <div className={clsx("")}>
      <Head>
        <meta
          name="viewport"
          content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0"
        />
      </Head>

      <Header
        withHero={withHero}
        primaryNavigation={navigation.primaryNavigation}
        site={site}
        siteLogo={siteLogo}
        hideBorder={hideHeaderBorder}
        hideYcnBranding={hideYcnBranding}
      />

      <AnimatePresence>
        {stateSnapshot.menu.isOpen && (
          <Menu items={navigation.primaryNavigation} site={site} />
        )}
      </AnimatePresence>

      <main className={clsx("flex lg:min-h-screen flex-col")}>{children}</main>
      <Footer
        primaryNavigation={navigation.primaryNavigation}
        hideYcnBranding={hideYcnBranding}
      />
    </div>
  );
};

export default Layout;
